<template>
  <div :class="$style.container">
    <Loader :isLoading="isLoader" />
    <router-link to="delivery-addresses/create" :class="$style.create">
      <Icon name="plus" :class="$style.plus" /> Создать адрес доставки
    </router-link>
    <vue-good-table
      :class="$style.table"
      :columns="columns"
      :rows="list"
      styleClass="vgt-table striped"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'action'" :class="$style.widthSpan">
          <router-link :to="`delivery-addresses/${props.row.id}`">
            <Icon name="edit" :class="$style.plus" />
          </router-link>
          <div :class="$style.delete">
            <Icon
              name="trash"
              :class="$style.plus"
              @click="removeDeliveryAddress(props.row.id)"
            />
          </div>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import delivery from '@/delivery'
import Loader from '@/components/atoms/LoadingBar.vue'
import Icon from '@/components/atoms/Icon'

export default {
  components: {
    Icon,
    Loader,
  },
  mounted() {
    this.getDealersDeliveryAddresses()
  },
  data() {
    return {
      columns: [
        {
          label: 'Название',
          field: 'cardName',
        },
        {
          label: 'Город',
          field: 'city',
        },
        {
          label: 'Улица',
          field: 'street',
        },
        {
          label: 'Номер дома',
          field: 'office',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      list: null,
      isLoader: false,
    }
  },

  methods: {
    async removeDeliveryAddress(addressId) {
      const isConfirm = confirm(
        `Вы уверены, что хотите удалить ${
          this.list.find((x) => x.id === addressId).street
        }`,
      )
      if (isConfirm) {
        this.isLoader = true
        const res =
          await delivery.AddwineCore.DealersActions.deleteDeliveryAddress(
            this.$route.params.id,
            addressId,
          )
        if (!res.error) {
          this.getDealersDeliveryAddresses()
        }
        this.isLoader = false
      }
    },
    async getDealersDeliveryAddresses() {
      this.isLoader = true
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getDealersDeliveryAddresses(
          this.$route.params.id,
        )
      if (error) {
        this.isLoader = false
        return
      }

      this.list = value
      this.isLoader = false
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
  .create {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border: 1px solid $light-gray;
    margin-bottom: 2rem;
    gap: 0.5rem;
    text-decoration: none;
    color: $dark-gray;
    &:hover {
      background: $white;
    }
  }
  .status {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: $error;
  }
  .successStatus {
    background-color: $complite;
  }
  .plus {
    fill: $light-gray;
    width: 1rem;
    height: 1rem;
  }
  .widthSpan {
    width: 1rem;
    display: flex;
    gap: 1rem;
  }
}
</style>
